/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import "./RateOrder.css";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { RateProduct } from "./RateProduct";
import { StarsRating } from "./StarsRating";
import useQuery from "./useQuery";
import { Ring } from "./Ring";
import { NpsScore } from "./NpsScore";
import { NetPromoterScoreSurvey } from "./NetPromoterScoreSurvey";

export function RateOrder(props) {
  const { external } = props;
  const { customer, orderToken, orderId, sku } = useParams();
  const query = useQuery();
  const [orderItems, setOrderItems] = useState([]);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [npsRating, setNpsRating] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNpsModalVisible, setIsNpsModalVisible] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [npsSurvey, setNpsSurvey] = useState(null);
  const [copyCoupon, setCopyCoupon] = useState(false);
  const [allowCoupon, setAllowCoupon] = useState(false);
  const [storeReview, setStoreReview] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setCopyCoupon(false);
    }, 5000);
  }, [copyCoupon]);

  const handleCopy = (e, code) => {
    e.preventDefault();
    setCopyCoupon(true);
    navigator.clipboard.writeText(code);
  };

  const getCoupons = async () => {
    setIsLoading(true);
    var couponData = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/coupon/`
    );
    setCoupon(couponData.data);
    setIsLoading(false);
  };

  const getOrderDetails = async () => {
    setIsLoading(true);

    var customerData = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/info`
    );

    setCustomerInfo(customerData.data);

    let orderDataUrl = "";
    if (orderToken && orderToken !== "") {
      if (!external)
        orderDataUrl = `${process.env.REACT_APP_API_URL}/${customer}/orders/${orderToken}/${orderId}`;
      else
        orderDataUrl = `${process.env.REACT_APP_API_URL}/${customer}/external-orders/${orderToken}/${orderId}`;
    } else if (sku && sku !== "") {
      orderDataUrl = `${process.env.REACT_APP_API_URL}/${customer}/orders/${orderId}/sku/${sku}`;
    }

    var orderData = await axios.get(orderDataUrl);
    setOrderItems(orderData.data);

    if (customerData.data.settings.embedNpsSurvey) {
      const npsData = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/${customer}/nps/order/${orderId}?token=${orderToken ?? ""}&sku=${
          sku ?? ""
        }`
      );
      setNpsSurvey(npsData.data);
    }

    if (customerData.data.settings?.enableStoreReview && !external) {
      if (!orderData.data.products[0]) {
        return;
      }

      setStoreReview({
        active: orderData.data.storeReview === null ? true : false,
        isStore: true,
        customer: customer,
        logo: customerData.data.logo,
        orderId: orderId,
        sku: "store-reviews",
        name: customerData.data.name,
        _id: orderData.data.products[0]._id,
        userId: orderData.data.products[0].userId,
        rating: orderData.data.storeReview?.rating || 0,
      });
    }

    getCoupons();
    setIsLoading(false);

    if (!firstLoaded) setFirstLoaded(true);
  };

  useEffect(() => {
    getOrderDetails();
  }, [orderToken, sku]);

  useEffect(() => {
    if (firstLoaded) {
      var ratingClicked = query.get("ratingClicked");
      var npsRating = query.get("npsRating");
      if (ratingClicked) {
        var item = orderItems.products.find((x) => x.sku === sku);
        if (
          item &&
          item.active &&
          ratingClicked &&
          ratingClicked >= 1 &&
          ratingClicked <= 5
        ) {
          setSelectedProduct(item);
          setSelectedRating(ratingClicked);
          setTimeout(() => {
            setIsModalVisible(true);
          }, 500);
        }
      }
    }

    if (npsRating) {
      setNpsRating(parseInt(npsRating));
      setTimeout(() => {
        setIsNpsModalVisible(true);
      }, 500);
    }
  }, [firstLoaded]);

  return (
    <div className="rate-order-page">
      {customerInfo && (
        <>
          {customerInfo.importCssWebApp && (
            <link rel="stylesheet" href={customerInfo.importCssWebApp} />
          )}

          <div className="logo">
            {customerInfo && (
              <img src={customerInfo.logo} alt={customerInfo.name} />
            )}
          </div>
          {isLoading && (
            <div className="loading-container">
              <Ring />
            </div>
          )}
          {!isLoading && (
            <>
              <h3>
                {orderItems.products?.some((x) => x.active)
                  ? "Avalie os produtos da sua última compra"
                  : "Você já avaliou todos os produtos!"}
              </h3>
              <div className={`order-items ${customerInfo.settings.storeReviewFirst ? 'store-review-first' : ''}`}>
                {orderItems.products.map((item) => {
                  return (
                    <div
                      key={item.sku}
                      className={`order-item ${item.active ? "" : "done"}`}
                      onClick={() => {
                        if (item.active) {
                          setSelectedProduct(item);
                          setIsModalVisible(true);
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      {!item.active && <div className="badge"></div>}
                      <img
                        className="picture"
                        alt=""
                        src={
                          item.product?.image
                            ? item.product.image
                            : "/image.jpeg"
                        }
                      />
                      <div className="details">
                        <div className="item-name">
                          {item.product?.name ?? `Ref. ${item.sku}`}
                        </div>
                        <div className="rating">
                          <StarsRating
                            value={item.review ? item.review.rating : 0}
                            onClickDisabled={(value) => {
                              setSelectedRating(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {customerInfo.settings.enableStoreReview &&
                  !external && (
                    <div
                      className={`order-item store-review ${
                        storeReview.active ? "" : "done"
                      }`}
                      onClick={() => {
                        if (storeReview.active) {
                          setSelectedProduct(storeReview);
                          setIsModalVisible(true);
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      {!storeReview.active && <div className="badge"></div>}
                      <img
                        alt={customerInfo.name}
                        className="storeLogo"
                        src={customerInfo.logo}
                      />
                      <div className="details">
                        <div className="item-name">
                          Avalie sua experiência na loja {customerInfo.name}
                        </div>
                        <div className="rating">
                          <StarsRating
                            value={storeReview.rating}
                            onClickDisabled={(value) => {
                              setSelectedRating(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {customerInfo.settings.embedNpsSurvey &&
                  npsSurvey &&
                  !external && (
                    <div
                      className={`order-item nps ${npsSurvey.active ? "" : "done"}`}
                      onClick={() => {
                        if (npsSurvey.active) {
                          setIsNpsModalVisible(true);
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      {!npsSurvey.active && <div className="badge"></div>}
                      <div className="details">
                        <div className="item-name">
                          {npsSurvey.survey.npsQuestion}
                        </div>
                        <div className="rating">
                          <NpsScore
                            value={-1}
                            onClickDisabled={(value) => {
                              setNpsRating(value);
                              setIsNpsModalVisible(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}
          
          <RateProduct
            modal
            show={isModalVisible}
            customerInfo={customerInfo}
            product={selectedProduct}
            external={external}
            initialRating={selectedRating}
            onHide={() => {
              getOrderDetails();
              setIsModalVisible(false);
            }}
            onComplete={() => {
              setAllowCoupon(true);
            }}
          />

          <NetPromoterScoreSurvey
            show={isNpsModalVisible}
            modal={true}
            surveyData={npsSurvey}
            customerData={customerInfo}
            initialRating={npsRating}
            onHide={() => {
              getOrderDetails();
              setNpsRating(-1);
              setIsNpsModalVisible(false);
            }}
          />

          {allowCoupon && coupon && (
            <div className="coupon-div">
              <div className="coupon-text">
                <p className="line1">
                  Você ganhou um cupom de desconto de{" "}
                  {coupon.isPercentage ? (<strong>{coupon.discountPercentage}%</strong>) : (<strong>{coupon.discountPercentage}</strong>)}
                </p>
                <p className="line2">
                  Use o código <strong>{coupon.couponCode}</strong> em seu
                  próximo pedido
                </p>
                <p className="line3">
                  O cupom é válido por {coupon.couponValidity} dias
                </p>
                <p className="line4">
                  {!copyCoupon && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="icone"
                      >
                        {" "}
                        <polygon
                          fill="var(--ci-primary-color, currentColor)"
                          points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432"
                          class="ci-primary"
                        />{" "}
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z"
                          class="ci-primary"
                        />{" "}
                      </svg>
                      <a
                        href="javascript:;"
                        onClick={(event) =>
                          handleCopy(event, coupon.couponCode)
                        }
                      >
                        copiar código
                      </a>
                    </>
                  )}
                  {copyCoupon &&
                    `Seu codigo foi copiado para sua area de transferencia`}
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
